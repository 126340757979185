import React from "react";
import PropTypes from "prop-types";

function Wrapper({ tag, className, forwardRef, title, children }) {
  let classList = [];
  const Tag = tag;

  classList = classList.concat(
    Array.isArray(className) ? className : className.split(" "),
  );
  return tag ? (
    <Tag className={classList.join(" ")} title={title} ref={forwardRef}>
      {children}
    </Tag>
  ) : (
    children
  );
}

Wrapper.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  title: PropTypes.string,
  children: PropTypes.node,
};

Wrapper.defaultProps = {
  tag: null,
  className: "",
  forwardRef: null,
  title: null,
  children: null,
};

Wrapper.displayName = "Wrapper";

export default Wrapper;
